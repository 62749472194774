<!--
 * @Author: lbh
 * @Date: 2021-04-28 17:49:46
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-28 18:16:06
 * @Description: QR Code點餐
-->
<template>
  <div
    class="gsq-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="ricepon"
      child='qrcode'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="QR Code點餐"
        content="省時方便，減低落錯單機會"
        :type="4"
        :wtype="window.type"
        :image="getImgPath('Ricepon_products_top01.jpg')"
        :bgImage="getImgPath('pbannerqq.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        title="QR Code點餐"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0
      },
      bgImage: ''
    }
  },
  mounted () {
    util.setTitle('QR Code點餐 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [util.getImgPath('QR_code-2.jpg')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: '', items: [
          { title: 'APP – 支持免註冊點餐' },
          { title: 'APP – 掃一下馬上點餐' },
          { title: 'APP – 支持一桌多機' },
          { title: 'APP – 離座信息提醒' },
          { title: 'APP – QR指定餐牌' },
          { title: 'APP – 食品數量上限控制' },
          { title: 'POS – 即時沽清控制' },
          { title: 'POS – 時段打印分流' },
          { title: 'POS – 食品項目匯合打印 廚房單 – 頭單提醒' },
          { title: '廚房單 – Last order 提醒 QR Code 一碼多用' },
          { title: '(下載APP/點菜/加入會員卡) 餐牌公告提醒' },
        ],
      },

    ];
    this.graphic.datas = datas;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.2);
      if (w < 700) {
        this.window.type = '600';
        // this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else if (w < 1000) {
        this.window.type = '900';
        // this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
